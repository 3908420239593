import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

const { accountNumbers } = CommonParameters;

export const paymentSettingsCancel: IMcAppType = {
  name: "paymentSettingsCancel",
  path: "/my/payment-settings/cancel",
  parameters: {
    properties: { accountNumbers },
  },
  description: "Cancel direct debit for a set of given accounts numbers",
  navFlow: NavFlow.FOCUS,
  title: "Cancel direct debit",
  parent: Tab.MORE,
  conditions: {
    intent: "paymentSettingsCancel",
    customerType: CustomerType.kraken,
  },
};
