import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

/**
 * @deprecated we now need to provide the charger id. Remove once widget deployed to prod.
 */
export const chargingHistory_KRAKEN: IMcAppType = {
  name: "chargingHistory_KRAKEN",
  path: "/my/service/ev",
  description: "Origin ev charging history",
  navFlow: NavFlow.STANDARD,
  title: "Charging history",
  parent: Tab.MORE,
  conditions: {
    intent: "chargingHistory",
    customerType: CustomerType.kraken,
  },
};

export const evChargingHistory_KRAKEN: IMcAppType = {
  name: "evChargingHistory_KRAKEN",
  path: "/my/service/ev/charger/:chargerId/history",
  parameters: {
    required: ["chargerId"],
    properties: { chargerId: CommonParameters.chargerId },
  },
  description: "EV Charger charging history",
  navFlow: NavFlow.STANDARD,
  title: "Charging history",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "evChargerChargingHistory",
    customerType: CustomerType.kraken,
  },
};
