import React from "react";

import styled, { IntrinsicElementsKeys } from "styled-components";
import { NavLinkIndentation } from "./NavLinkBase";

const withStyles = (cmp: IntrinsicElementsKeys) =>
  styled(cmp)<{ paddingLeft: number }>(
    ({ theme: { palette }, paddingLeft }) => ({
      display: "flex",
      textDecoration: "none",
      alignItems: "center",
      width: "100%",
      paddingLeft: `${paddingLeft}px`,
      "&:hover": {
        '&& [class*="MuiSvgIcon-root"], & > *': {
          color: palette.primary.main,
        },
      },
    })
  );

const StyledA = withStyles("a");
const StyledSpan = withStyles("span");

interface INavLinkContainer {
  children: React.ReactNode;
  id: string;
  href?: string;
  indent?: NavLinkIndentation;
}

export const NavLinkContainer = (props: INavLinkContainer) => {
  const { href, id, children, indent = NavLinkIndentation.None } = props;

  return href ? (
    <StyledA tabIndex={0} paddingLeft={indent} href={href} data-id={id} id={id}>
      {children}
    </StyledA>
  ) : (
    <StyledSpan paddingLeft={indent} data-id={id} id={id}>
      {children}
    </StyledSpan>
  );
};
