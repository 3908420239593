import * as React from "react";
import styled from "styled-components";

import { MenuBar } from "./MenuBar";
import { MobileSlideinNav } from "./MobileSlideinNav";

export const NavWrapper = styled.div`
  background: white;
  position: relative;
`;

const useMobileMenu = () => {
  const [showMenu, setShowMenu] = React.useState(false);

  React.useEffect(() => {
    // toggle body scroll
    if (showMenu) {
      document.body.style.setProperty("height", "100vh", "important");
      document.body.style.setProperty("overflow", "hidden", "important");
    } else {
      document.body.style.removeProperty("height");
      document.body.style.removeProperty("overflow");
    }
  }, [showMenu]);

  const toggleShowMenu = () => {
    setShowMenu((state) => !state);
  };

  return [showMenu, toggleShowMenu] as const;
};

export const Navigation = () => {
  const [showMenu, toggleMenu] = useMobileMenu();

  return (
    <NavWrapper data-comment="Nav">
      <MenuBar logoLinkHref="/" toggleMenu={toggleMenu} />
      <MobileSlideinNav showMenu={showMenu} onCloseClick={toggleMenu} />
    </NavWrapper>
  );
};
