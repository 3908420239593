import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const refunds: IMcAppType = {
  name: "refunds",
  path: "/my/refunds",
  description: "Transfer or refund credit",
  navFlow: NavFlow.STANDARD,
  title: "Transfer or refund my credit",
  parent: Tab.MORE,
  conditions: {
    intent: "refunds",
    customerType: CustomerType.kraken,
  },
};

export const refundsRequest: IMcAppType = {
  name: "refundsRequest",
  path: "/my/refunds/request",
  description: "Transfer or refund credit",
  navFlow: NavFlow.FOCUS,
  title: "Transfer or refund my credit",
  parent: Tab.MORE,
  conditions: {
    intent: "refundsRequest",
    customerType: CustomerType.kraken,
  },
};
