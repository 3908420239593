import React, { ButtonHTMLAttributes } from "react";

import { Box, TrackedLink, useTracking } from "@origin-digital/ods-core";
import styled from "styled-components";

import { COLOURS, Z_INDEX } from "../../consts/style";
import { CloseIcon } from "../Svg/Close";

export const MenuContainer = styled(Box)`
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${COLOURS.GREY.WHITE};
  position: fixed;
  z-index: ${Z_INDEX.MOBILE_MENU_CONTAINER};

  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }

  transition: left 300ms ease-in-out;
  &.showMenu {
    left: 0;
  }
`;

export const ShadeOverlay = styled(Box)`
  pointer-events: none;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  position: fixed;
  z-index: ${Z_INDEX.MOBILE_MENU_CONTAINER - 1};
  opacity: 0;

  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }

  transition: opacity 300ms ease-in-out;
  /* stylelint-disable */
  /* Fix for IOS  */
  -webkit-transition: opacity 300ms ease-in-out;
  /* stylelint-enable */
  &.showMenu {
    opacity: 0.7;
  }
`;

export const CloseButton = styled(CloseIcon)`
  fill: ${(p) => p.theme.colors.primary};
`;

export const Link = styled(TrackedLink)`
  text-decoration: none;
  color: #232323;
  font-size: 24px;
  line-height: 28px;
`;

export const LinkContainer = styled.div`
  /* pulls the clickable area in to just be the size of the text */
  width: fit-content;
`;

const IconButton = styled.button`
  all: unset;

  /* 
  ** removes 4px padding from bottom of icons 
  ** https://stackoverflow.com/questions/8600393/there-is-a-4px-gap-below-canvas-video-audio-elements-in-html5
  */
  svg {
    display: block;
  }

  &:focus,
  &:focus-visible {
    outline: revert;
  }

  &:hover {
    cursor: pointer;
  }
`;

type TrackedIconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  trackingLabel: string;
  "data-id": string;
};

export const TrackedIconButton = ({
  onClick,
  children,
  trackingLabel,
  "data-id": dataId,
  ...rest
}: TrackedIconButtonProps): JSX.Element => {
  const { onClickCapture, ref } = useTracking({
    children: trackingLabel,
    "data-id": dataId,
    type: "Button",
  });

  return (
    <IconButton
      {...rest}
      onClick={(e) => {
        onClickCapture?.();
        onClick(e);
      }}
      ref={ref}
      data-id={dataId}
    >
      {children}
    </IconButton>
  );
};
