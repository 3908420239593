import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

const { accountNumbers } = CommonParameters;

export const directDebitCancel_KRAKEN: IMcAppType = {
  name: "directDebitCancel_KRAKEN",
  path: "/my/direct-debit/cancel",
  parameters: {
    properties: { accountNumbers },
  },
  description: "Cancel Direct Debit for a set of given accounts numbers",
  navFlow: NavFlow.FOCUS,
  title: "Cancel direct debit",
  parent: Tab.MORE,
  conditions: {
    intent: "directDebitCancel",
    customerType: CustomerType.kraken,
  },
};
