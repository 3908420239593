import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const paymentSettingsManage: IMcAppType = {
  name: "paymentSettingsManage",
  path: "/my/payment-settings",
  description: "Manage Payment Settings",
  navFlow: NavFlow.STANDARD,
  title: "Payment settings",
  parent: Tab.MORE,
  conditions: {
    intent: "paymentSettingsManage",
    customerType: CustomerType.kraken,
  },
};
