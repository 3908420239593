import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const disconnect_KRAKEN: IMcAppType = {
  name: "disconnect_KRAKEN",
  path: "/my/disconnect",
  description: "Close your accounts here in just a few simple steps.",
  navFlow: NavFlow.STANDARD,
  title: "Disconnect my property",
  parent: Tab.MORE,
  conditions: {
    intent: "disconnect",
    customerType: CustomerType.kraken,
  },
};

export const disconnectServiceSelector_KRAKEN: IMcAppType = {
  name: "disconnectServiceSelector_KRAKEN",
  path: "/my/disconnect/service-selector",
  description: "Close your accounts here in just a few simple steps.",
  navFlow: NavFlow.FOCUS,
  title: "Disconnect my property",
  parent: Tab.MORE,
  conditions: {
    intent: "disconnectServiceSelector",
    customerType: CustomerType.kraken,
  },
};
