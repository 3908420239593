import { CustomerType } from "@origin-digital/platform-enums";
import { getMcAppFromBaseProps } from "../lib/baseProps";
import { IBaseProps, IMcAppType, NavFlow, Tab } from "../mcApp.types";

const baseProps = {
  path: "/my/profile",
  navFlow: NavFlow.MODAL,
  parent: Tab.MORE,
};

interface IProfileBaseProps extends IBaseProps {
  krakenSubpath?: string;
}

const createProfileMcApp = ({
  name,
  krakenSubpath,
  ...props
}: IProfileBaseProps): IMcAppType => {
  name = `profile${name}`;
  return getMcAppFromBaseProps({
    ...props,
    path: "/my/profile",
    name: `${name}_KRAKEN`,
    intent: name,
    customerType: CustomerType.kraken,
    subPath: krakenSubpath ?? props.subPath,
  });
};

const profileApps: IProfileBaseProps[] = [
  {
    name: "",
    subPath: "",
    description: "View account profile",
    title: "Update my details",
    ...baseProps,
  },
  {
    name: "Address",
    subPath: "update-postal-address",
    description: "Update postal address",
    title: "Postal Address",
    ...baseProps,
  },
  {
    name: "Details",
    subPath: "username-password",
    description: "Update username and password",
    title: "Username and password",
    ...baseProps,
  },
  {
    name: "Dob",
    subPath: "update-date-of-birth",
    description: "Update date of birth",
    title: "Date of birth",
    ...baseProps,
  },
  {
    name: "Email",
    subPath: "update-email-address",
    description: "Update email",
    title: "Email address",
    ...baseProps,
  },
  {
    name: "Landline",
    subPath: "update-home-phone",
    description: "Update home phone",
    title: "Home phone",
    ...baseProps,
  },
  {
    name: "Mobile",
    subPath: "update-mobile-phone",
    description: "Update mobile phone",
    title: "Mobile phone",
    ...baseProps,
  },
  {
    name: "Preferences",
    subPath: "contact-settings",
    krakenSubpath: "contact-preferences",
    description: "Update contact settings",
    title: "Contact Settings",
    ...baseProps,
  },
];

export const profile: IMcAppType[] = profileApps.map((app) =>
  createProfileMcApp(app)
);
