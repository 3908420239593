/**
 * Libraries provided by TAL to consuming apps.
 */
// @ts-ignore
window["React"] = require("react");
window["ReactDOM"] = require("react-dom");
window["styled"] = require("sc3");
window["sc5"] = require("styled-components");
window["MuiStyles"] = require("@material-ui/styles");

export {};
