import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const ServiceUsageData: IMcAppType = {
  name: "serviceUsageData_KRAKEN",
  path: "/my/service/:serviceType/:agreementId/request-usage-data",
  description: "Request usage data",
  parameters: {
    required: ["serviceType", "agreementId"],
    properties: {
      agreementId: CommonParameters.agreementId,
      serviceType: CommonParameters.serviceType,
    },
  },
  navFlow: NavFlow.MODAL,
  title: "Request usage data",
  parent: Tab.MORE,
  conditions: {
    intent: "serviceUsageData",
    customerType: CustomerType.kraken,
  },
};
