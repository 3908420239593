import { TrackedLink } from "@origin-digital/ods-core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import nav from "./links-desktop.json";

const NavBar = styled.div`
  margin: auto 0px;

  display: none;
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: grid;
    grid-auto-flow: column;

    gap: 18px;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.xl}) {
    gap: 30px;
  }
`;

// This component is a child of the actual <a> tag. We give it a margin. this way
// the hitbox (from the parent) goes past the edges of the text (making it
// easier to click) but the text itself has a space around the sides, and so
// does the underline when it is positioned relative to the LinkText
const LinkText = styled.div`
  /* allow bottom hover/active borders to absolutely position themselves
  relative to the actual text */
  position: relative;
`;

const Link = styled(TrackedLink)<{ active: boolean }>`
  text-decoration: none;
  color: ${(p) => p.theme.colors.grey600};
  font-size: 14px;
  line-height: 46px;
  font-weight: 600;
  box-sizing: border-box;

  @media (min-width: ${(p) => p.theme.breakpoints.xl}) {
    line-height: 66px;
  }

  /* stylelint-disable */
  & > ${LinkText}:after {
    /* stylelint-enable */
    position: absolute;
    content: "";
    bottom: 0;

    border-bottom: 4px solid ${(p) => p.theme.colors.grey200};

    transition: 0.2s ease-in-out 0s;

    /* starting states for transition */
    left: 50%;
    width: 0;
    opacity: 0;

    ${(p) =>
      p.active
        ? `
        /* Active state for links */
        border-bottom: 4px solid #ffc72c; 
        left: 0;
        width: 100%;
        opacity: 1
        `
        : ""}
  }

  /* stylelint-disable */
  &:hover > ${LinkText}:after {
    /* stylelint-enable */
    width: 100%;
    left: 0;
    opacity: 1;
  }
`;

export const PrimaryNav = () => {
  const [pathname, setPathname] = useState<string | undefined>(undefined);

  useEffect(() => {
    setPathname(window.location.pathname);
  }, []);

  return (
    <NavBar>
      {nav.primaryLinks.map((link) => (
        <Link
          trackingType="Link"
          trackingLabel={link.title}
          data-id={`navigation:${link.title}`}
          key={link.title}
          href={link.link}
          active={pathname?.split(link.link)[0] === ""}
        >
          <LinkText>{link.title}</LinkText>
        </Link>
      ))}
    </NavBar>
  );
};
