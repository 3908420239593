import { eventListener, TOPICS } from "@origin-digital/event-dispatcher";
import { EnvironmentNames } from "@origin-digital/platform-enums";
import { NATIVE_EMU_QUERY_STRING_IDENTIFIER } from "@origin-digital/native-features";

import { nativeEmulatorPlatformHandler } from "./handlers/nativeEmulatorPlatformHandler";
import { nativePlatformHandler } from "./handlers/nativePlatformHandler";
import { webPlatformHandler } from "./handlers/webPlatformHandler";
import { SubscribeTopics } from "./mesh.types";

//Listener list should match type SubscribeTopics
const listenerNames: SubscribeTopics["topic"][] = [
  TOPICS.AUTH_USER,
  TOPICS.NAV_TO,
  TOPICS.NAV_TO_URL,
  TOPICS.NAV_BACK,
  TOPICS.NAV_CLOSE,
  TOPICS.NAV_COMPLETE,
  TOPICS.NAV_HREF,
  TOPICS.NAV_HREF_TITLE,
  TOPICS.HTTP_REQ_ERROR,
  TOPICS.OPEN_RESOURCE,
  TOPICS.FETCH_ACCESS_TOKEN,
  TOPICS.FETCH_PLATFORM_PARAMETERS,
  TOPICS.FETCH_NATIVE_FEATURES,
  TOPICS.TOAST_SHOW,
  TOPICS.MODAL_OPEN,
  TOPICS.MODAL_CLOSE,
  TOPICS.CHAT_MESSENGER_SHOW,
  TOPICS.CHAT_MESSENGER_HIDE,
  TOPICS.ASYNC_MESSENGER_OPEN,
  TOPICS.ASYNC_MESSENGER_CLOSE,
  TOPICS.APP_DIAGNOSTICS,
  TOPICS.SURVEY_INIT,
  TOPICS.SURVEY_CLOSE,
  TOPICS.LINK_EV_ACCOUNT,
  TOPICS.DEVICE_WALLET_SUPPORTED,
  TOPICS.DEVICE_WALLET_OPEN,
];

let unSubListeners: () => void;

export interface IInitHandler {
  isNative: boolean;
  environment: EnvironmentNames;
  /**
   * current query string as a parameters object
   */
  parameters: Record<string, string>;
}

export function initHandler({
  isNative,
  environment,
  parameters,
}: IInitHandler): void {
  const gePlatformHandler = () => {
    if (parameters[NATIVE_EMU_QUERY_STRING_IDENTIFIER] != null)
      return nativeEmulatorPlatformHandler({ environment });

    if (isNative) return nativePlatformHandler({ environment });

    return webPlatformHandler({ environment });
  };
  const platformHandler = gePlatformHandler();
  unSubListeners = eventListener.addListeners(
    listenerNames,
    platformHandler.listener
  );
}

// export for testing
export function __unSubListeners(): void {
  if (unSubListeners) {
    unSubListeners();
  }
}
