import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const directDebitManage_KRAKEN: IMcAppType = {
  name: "directDebitManage_KRAKEN",
  path: "/my/direct-debit",
  description: "Manage Direct Debit",
  navFlow: NavFlow.STANDARD,
  title: "Direct debit",
  parent: Tab.MORE,
  conditions: {
    intent: "directDebitManage",
    customerType: CustomerType.kraken,
  },
};
