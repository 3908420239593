import * as React from "react";
import styled from "styled-components";
import { TrackedLink } from "@origin-digital/ods-core";

import nav from "./links-desktop.json";
import { SSRLoginSwitcher } from "./SSRLoginSwitcher";

const AccountNavContainer = styled.div`
  /* flex gap still not supported by some of our supported browsers (I think it's IOS).
  We can use grid gap as a drop in replacement instead */
  display: grid;
  grid-auto-flow: column;

  gap: 16px;
  @media (min-width: ${(p) => p.theme.breakpoints.xl}) {
    gap: 24px;
  }

  & > * {
    margin: auto 0;
  }
`;

const DesktopLink = styled(TrackedLink)`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: block;

    /* match the line height of the login button next to it */
    line-height: 16px;

    text-decoration: none;
    color: ${(p) => p.theme.colors.grey400};
    font-size: 14px;
  }

  &:hover {
    color: ${(p) => p.theme.colors.primary};
  }
`;

export const SecondaryNav = () => {
  return (
    <AccountNavContainer>
      {nav.secondaryLinks.map((link) => (
        <DesktopLink
          key={link.title}
          href={link.link}
          trackingType="Link"
          trackingLabel={link.title}
          data-id={`navigation:${link.title}`}
        >
          {link.title}
        </DesktopLink>
      ))}
      <div>
        <SSRLoginSwitcher />
      </div>
    </AccountNavContainer>
  );
};
