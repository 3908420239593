import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

const { surveyType } = CommonParameters;

export const Survey: IMcAppType = {
  name: "survey",
  path: "/my/survey",
  parameters: {
    properties: { surveyType },
  },
  description: "Task completion survey",
  navFlow: NavFlow.MODAL,
  title: "",
  parent: Tab.MORE,
  conditions: {
    intent: "survey",
    customerType: CustomerType.kraken,
  },
};
