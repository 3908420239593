import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

// new authenticated renewal plan selection page
export const renewalPlanSelection: IMcAppType = {
  name: "renewalPlanSelection",
  path: "/my/auth-renew-signup/plan-selector",
  description: "Auth renewal plan selection",
  navFlow: NavFlow.FOCUS,
  title: "Renew your plan",
  parent: Tab.MORE,
  conditions: {
    intent: "renewalPlanSelection",
    customerType: CustomerType.kraken,
  },
};

export const renewalPlanSignup: IMcAppType = {
  name: "renewalPlanSignup",
  path: "/my/auth-renew-signup",
  description: "Auth renewal plan selection",
  navFlow: NavFlow.STANDARD,
  title: "Renew your plan",
  parent: Tab.MORE,
  conditions: {
    intent: "renewalPlanSignup",
    customerType: CustomerType.kraken,
  },
};

export const renewPlanFocused: IMcAppType = {
  name: "renewPlanFocused",
  path: "/my/auth-renew-signup/renew",
  description: "Auth renewal focused landing page for IVR",
  navFlow: NavFlow.FOCUS,
  title: "Renew your plan",
  parent: Tab.MORE,
  conditions: {
    intent: "renewPlanFocused",
    customerType: CustomerType.kraken,
  },
};

export const changePlanSelection: IMcAppType = {
  name: "changePlanSelection",
  path: "/my/auth-change-plan",
  description: "Auth change plan",
  navFlow: NavFlow.FOCUS,
  title: "Change your plan",
  parent: Tab.MORE,
  conditions: {
    intent: "changePlanSelection",
    customerType: CustomerType.kraken,
  },
};
