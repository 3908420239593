import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

// parameters taken from here:
// https://bitbucket.origin.com.au/projects/OD/repos/yasf-micro-site/browse/client/src/utils/url-param/AppURLSearchParams.ts#6

export const signUp: IMcAppType = {
  name: "signUp",
  path: "/sign-up",
  description: "Sales form sign up",
  navFlow: NavFlow.FOCUS,
  title: "",
  parent: Tab.MORE,
  parameters: {
    properties: {
      addressId: {
        type: "string",
        description: "SAP Address Id (use for address prepopulation)",
      },
      agentId: {
        type: "string",
        description: "Sales Agent ID from kiosk campaign page",
      },
      bbPlanSpeed: {
        type: "string",
        description: "Broadband speed tiers.",
      },
      bbPromoCode: {
        type: "string",
        description: "Broadband promo code",
      },
      broadband: {
        type: "boolean",
        description: "Used to distinguish the broadband journey",
      },
      caid: {
        type: "string",
        description: "Contract account id passed from digishift to auth renew.",
      },
      campaign: {
        type: "string",
        description: "Campaign code",
      },
      defaultEDR: {
        type: "boolean",
        description: "HMC selected plan with EDR or not",
      },
      edr: {
        type: "boolean",
        description: "Hire edr if false",
      },
      isCustomer: {
        type: "boolean",
        description:
          "Default value for the Are you an Existing Customer question",
      },
      isEmployee: {
        type: "boolean",
        description: "if isEmployee param is set to true",
      },
      journeyType: {
        type: "string",
        description: "Move | Switch use to lockdown the connection scenario",
      },
      planFetchStrategy: {
        type: "string",
        description:
          "Given | Targeted | Marketing - Given = user chose plan from Compare Plans, Targeted use the Authenticated TargetPlan API, Marketing = use marketing-product API (e.g. from Basic Move)",
      },
      podid: {
        type: "string",
        description: "Pod id for VC.",
      },
      referralInfo: {
        type: "string",
        description: "Referral code",
      },
    },
  },
};
