import React from "react";

import { Box, TrackedLink } from "@origin-digital/ods-core";

import styled from "styled-components";
import { navigationBarHeight } from "./helpers/consts";
import { IKrakenAuthNavProps } from "./interfaces";

const StyledContainer = styled(Box)<{ noBorder: boolean }>(
  ({ theme: { palette }, noBorder }) => ({
    paddingLeft: 5,
    paddingRight: 5,
    width: 65,
    height: navigationBarHeight,
    borderRight: `1px solid ${palette.grey["200"]}`,

    ...(noBorder && {
      borderRight: 0,
    }),
  })
);

interface ISmallerLogo extends IKrakenAuthNavProps {
  href: string;
  id: string;
}

const SmallerLogo = ({ href, id, desktopVariant }: ISmallerLogo) => {
  return (
    <StyledContainer noBorder={desktopVariant === "slim"}>
      <TrackedLink
        trackingLabel=""
        trackingType="desktop-logolink"
        href={href}
        id={id}
        data-id={id}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 145 165"
          height="64px"
        >
          <defs>
            <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor="#FF373C" />
              <stop offset="100%" stopColor="#FFC72C" />
            </linearGradient>
          </defs>
          <title>Origin Energy</title>
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h145v165H0z" />
            <path
              fill="#FF373C"
              d="M90.484 121.05v-9.511h-4.823v1.064a.116.116 0 0 0-.037-.028 10.243 10.243 0 0 0-5.212-1.398c-5.573 0-10.035 4.376-10.035 9.873a9.815 9.815 0 0 0 3.537 7.54 9.74 9.74 0 0 0-3.537 7.531c0 5.45 4.462 9.91 10.035 9.91 5.61 0 10.072-4.46 10.072-9.91a9.724 9.724 0 0 0-3.536-7.531 9.785 9.785 0 0 0 3.536-7.54zm-15.284 0c0-2.785 2.342-5.172 5.212-5.172 2.703 0 4.916 2.063 5.212 4.59.028.193.037.387.037.582 0 .194-.009.397-.037.583-.296 2.563-2.509 4.626-5.212 4.626-2.87 0-5.212-2.387-5.212-5.21zm10.424 15.663c-.296 2.564-2.509 4.626-5.212 4.626-2.87 0-5.212-2.386-5.212-5.218 0-2.784 2.342-5.171 5.212-5.171 2.703 0 4.916 2.063 5.212 4.589.028.194.037.388.037.582 0 .204-.009.399-.037.592zm33.449-16.385c0-3.66-1.95-4.456-3.901-4.456-1.95 0-3.902.756-3.902 4.377v10.184h-4.895v-18.897h4.895v1.392c1.075-.796 2.389-1.75 4.976-1.75 4.14 0 7.722 2.426 7.722 8.354v10.901h-4.895v-10.105zm-23.207 10.105h4.896v-18.897h-4.896v18.897zm-34.98 0h4.895v-18.897h-4.895v18.897zm-15.803-18.897h4.896v1.392c1.831-1.353 3.623-1.75 7.285-1.75v4.694c-6.25 0-7.285 1.75-7.285 5.012v9.549h-4.896v-18.897zM31.031 130.95c-5.573 0-10.031-4.456-10.031-9.906 0-5.49 4.458-9.867 10.03-9.867 5.614 0 10.072 4.376 10.072 9.867 0 5.45-4.458 9.906-10.071 9.906zm0-15.078c-2.866 0-5.215 2.387-5.215 5.172 0 2.824 2.349 5.211 5.215 5.211 2.906 0 5.254-2.387 5.254-5.211 0-2.785-2.348-5.172-5.254-5.172z"
            />
            <path
              fill="url(#a)"
              fillRule="nonzero"
              d="M43 0C24.41-.016 7.92 11.83 2.135 29.356-3.649 46.88 2.587 66.108 17.587 77c15.125-11.008 35.701-11.008 50.826 0 15-10.892 21.236-30.12 15.452-47.644C78.08 11.83 61.59-.016 43 0zm0 64.39c-12.109-.01-21.919-9.752-21.916-21.764.004-12.011 9.82-21.748 21.928-21.75 12.11-.004 21.93 9.727 21.94 21.738a21.669 21.669 0 0 1-6.424 15.403A22.024 22.024 0 0 1 43 64.39z"
              transform="translate(29.583 19)"
            />
          </g>
        </svg>
      </TrackedLink>
    </StyledContainer>
  );
};

export default SmallerLogo;
