/* eslint-disable no-negated-condition */
/* eslint-disable react/default-props-match-prop-types */
import React from "react";
import {
  Breadcrumb,
  BreadcrumbProps,
  Breadcrumbs,
  Heading,
  Hidden,
  Section,
  Stack,
} from "@origin-digital/ods-core";

import styled from "styled-components";
import {
  ComponentBaseProps,
  HeadingComponentVariants,
} from "@origin-digital/ods-types";
import { ActionBarMobile } from "./ActionBarMobile";
import { NavTitle } from "./NavTitle";
import { Box } from "./Box";

export interface Premise {
  premiseName: string;
  premiseId: string;
}

export type MobileNavButtonIconType = "back" | "close";
export type MobileNavButtonType = "none" | MobileNavButtonIconType;
export type MobilePropertySelectorType =
  | "none"
  | "compact"
  | "full"
  | "readOnly";

export interface Breadcrumbs
  extends Pick<BreadcrumbProps, "active" | "href" | "onClick"> {
  label: string;
}

export interface ActionBarProps extends ComponentBaseProps {
  title?: string;
  headingComponent?: HeadingComponentVariants;
  breadcrumbs?: Breadcrumbs[];
  mobileNavButton?: MobileNavButtonType;
  onClickMobileNavButton?: (
    event?: React.MouseEvent<HTMLButtonElement>
  ) => void | boolean;
  desktopVariant?: "default" | "slim";
}

const slimHeight = 44;
const fullHeight = 128;

const ViewContainer = styled(Box)<{
  desktopVariant: ActionBarProps["desktopVariant"];
}>`
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    justify-content: space-between;
    height: ${(p) => (p.desktopVariant === "slim" ? slimHeight : fullHeight)}px;
  }
`;

const StyledHidden = styled(Hidden)`
  width: 100%;
`;

export const ActionBar: React.FC<ActionBarProps> = ({
  title,
  headingComponent,
  breadcrumbs,
  mobileNavButton,
  onClickMobileNavButton,
  "data-id": dataId,
  desktopVariant,
}) => {
  return (
    <Box overflow="hidden">
      <Box
        data-id={dataId}
        backgroundColor="white"
        display="flex"
        flexDirection="column"
        style={{ flex: 1 }}
      >
        <ViewContainer
          desktopVariant={desktopVariant}
          display="flex"
          backgroundColor="white"
          width="full"
        >
          <StyledHidden below="lg">
            <Box width="full" display="flex" height="full" alignItems="center">
              {desktopVariant !== "slim" ? (
                <Section paddingY="none" data-id="desktop-container">
                  <Stack space="medium">
                    <Box
                      width="full"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Heading
                        variant="h2"
                        data-id="actionbar-heading-desktop"
                        component={headingComponent}
                      >
                        {title}
                      </Heading>
                    </Box>
                    {breadcrumbs?.length ? (
                      <Breadcrumbs>
                        {breadcrumbs.map(({ label, ...breadcrumbProps }) => (
                          <Breadcrumb key={label} {...breadcrumbProps}>
                            {label}
                          </Breadcrumb>
                        ))}
                      </Breadcrumbs>
                    ) : null}
                  </Stack>
                </Section>
              ) : (
                <Stack>
                  <NavTitle
                    mobileNavButton={mobileNavButton}
                    headingComponent={headingComponent}
                    onClickMobileNavButton={onClickMobileNavButton}
                    title={title}
                    desktopVariant={desktopVariant}
                    isDesktop={true}
                  />
                </Stack>
              )}
            </Box>
          </StyledHidden>
          <StyledHidden above="lg">
            <ActionBarMobile
              title={title}
              headingComponent={headingComponent}
              mobileNavButton={mobileNavButton}
              onClickMobileNavButton={onClickMobileNavButton}
            />
          </StyledHidden>
        </ViewContainer>
      </Box>
    </Box>
  );
};

ActionBar.displayName = "ActionBar";
ActionBar.defaultProps = {
  mobileNavButton: "none",
  headingComponent: "h1",
  "data-id": "action-bar",
  desktopVariant: "default",
};
