import React from "react";

import { Box, Hidden } from "@origin-digital/ods-core";

import styled from "styled-components";
import { IKrakenNavLink } from "./interfaces";
import { navigationBarHeight } from "./helpers/consts";
import { isActiveNavSection } from "./helpers/NavHelpers";
import { NavLinkContainer } from "./NavLinkContainer";
import { getIcon } from "./Icons/icons";

const LinkContainer = styled(Box)(({ theme: { palette } }) => ({
  display: "flex",
  minHeight: navigationBarHeight,
  borderLeft: `1px solid ${palette.grey["200"]}`,
  paddingLeft: 16,
  paddingRight: 16,
  cursor: "pointer",
}));

const NavLinkTitle = styled(Box)<{ active: boolean }>(
  ({ theme: { palette }, active }) => ({
    margin: 0,
    color: palette.grey["400"],
    fontSize: 12,
    lineHeight: "20px",
    fontWeight: 400,
    ...(active && {
      borderBottom: `2px solid ${palette.warning.main}`,
    }),
  })
);
const IconContainer = styled(Box)<{ mobile?: boolean }>(
  ({ theme: { palette }, mobile }) => ({
    display: "flex",
    position: "relative",
    "& svg": {
      fontSize: mobile ? 28 : 20,
    },
    '&& [class*="MuiSvgIcon-root"]': {
      height: 38,
      margin: mobile ? 0 : "0px 6px 0px 0px",
      color: palette.grey["300"],
    },
  })
);

type ICollapsibleNavLink = IKrakenNavLink;

export const CollapsibleNavLink = (props: ICollapsibleNavLink) => {
  const { linkConfig } = props;
  const { name, href, icon, title } = linkConfig;
  const active = isActiveNavSection(href);

  return (
    <>
      <Hidden above="md">
        <LinkContainer data-id="mobile-collapsible-link">
          <NavLinkContainer href={href} id={`${name}-container`}>
            <IconContainer mobile>{getIcon(icon)}</IconContainer>
          </NavLinkContainer>
        </LinkContainer>
      </Hidden>
      <Hidden below="md">
        <LinkContainer data-id="desktop-collapsible-link">
          <NavLinkContainer href={href} id={`${name}-container`}>
            <IconContainer>{getIcon(icon)}</IconContainer>
            <NavLinkTitle
              active={active}
              data-id={`${name}-title`}
              component="p"
            >
              {title}
            </NavLinkTitle>
          </NavLinkContainer>
        </LinkContainer>
      </Hidden>
    </>
  );
};
