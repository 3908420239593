import { CustomerType } from "@origin-digital/platform-enums";
import { IBaseProps, IMcAppType, NavFlow, Tab } from "../mcApp.types";
import { getMcAppFromBaseProps } from "../lib/baseProps";

const createContactUsMcApp = ({ name, ...props }: IBaseProps): IMcAppType[] => {
  return [
    getMcAppFromBaseProps({
      ...props,
      name: `${name}_UNAUTH`,
      path: "/contact",
      intent: name,
      customerType: CustomerType.unauthenticated,
    }),
    getMcAppFromBaseProps({
      ...props,
      name: `${name}_KRAKEN`,
      path: "/my/feedback/\\?formCode=rx_contact_us&title=Contact%20us",
      intent: name,
      customerType: CustomerType.kraken,
      ignorePathMapping: true,
    }),
  ];
};

const contactUsApp: IBaseProps[] = [
  {
    name: "contactUs",
    path: "",
    subPath: "",
    description: "Contact Us",
    navFlow: NavFlow.FOCUS,
    title: "Contact Us",
    parent: Tab.MORE,
  },
];

export const contactUs: IMcAppType[] = contactUsApp.flatMap((app) =>
  createContactUsMcApp(app)
);
