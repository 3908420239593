import * as React from "react";
import {
  OriginThemeProvider,
  TrackingProvider,
} from "@origin-digital/ods-core";
import { LinkComponentProps } from "@origin-digital/ods-types";
import { trackingProviderCaptureClick } from "@origin-digital/reporting-client";
import { parseMarkdownEvent } from "@origin-digital/event-dispatcher";
import {
  ReviewAlertFooterService,
  TrustPilotModal,
} from "@origin-digital/platform-personalisation";

import {
  FontStyles,
  ResetStyles,
  IconFontStyles,
  BrazeStyles,
} from "../../styles/global";
import { KrakenAppContent, UnauthAppContent } from "../AppContent";
import { SuppressAndroidInstallPrompt } from "../SuppressAndroidInstallPrompt";
import { CrisisBanner } from "../Banner";

import { PrefetchContext, PrefetchedContent } from "../prefetch";
import Breadcrumbs, { getBreadcrumbsContent } from "../Breadcrumbs";
import { InAppMessagingService } from "../InAppMessagingService";

const MeshLink = React.forwardRef<any, LinkComponentProps>((props, ref) => {
  const { href, onClick } = parseMarkdownEvent(props.href);
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      ref={ref}
      {...props}
      href={href}
      onClick={(e) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions, babel/no-unused-expressions
        onClick && onClick(e);
        return props.onClick && props.onClick(e);
      }}
    />
  );
});

//TODO: Type this exactly to match what tal-core passes on.
export interface AppProps {
  title: string;
  actionBar?: any;
  authenticated?: boolean;
  background?: "gray" | "white";
  channel?: string;
  children?: React.ReactNode;
  prefetchContent?: Record<string, PrefetchedContent>;
  noSeed?: boolean;
  platform?: string;
  presets?: string[];
  showHeader?: boolean | "unauth" | "scoped";
  showNativeAppAd?: boolean;
  enableCrisisBanner?: boolean;
}

const App = ({
  actionBar,
  channel,
  prefetchContent = {},
  platform,
  presets,
  showHeader = true,
  showNativeAppAd = true,
  enableCrisisBanner = true,
  title,
}: AppProps) => {
  const isKraken =
    platform === "kraken" || (presets && presets.includes("kraken"));
  const isNativeChannel = channel === "native";
  const breadcrumbsContent = getBreadcrumbsContent(prefetchContent);

  return (
    <>
      <FontStyles />
      <ResetStyles />
      <IconFontStyles />
      <BrazeStyles />

      {/* @ts-ignore fails when upgrading to ts 4*/}
      <OriginThemeProvider linkComponent={MeshLink}>
        <TrackingProvider onTrackingCapture={trackingProviderCaptureClick}>
          <PrefetchContext.Provider value={prefetchContent}>
            <TrustPilotModal />
            <ReviewAlertFooterService />
            <InAppMessagingService />
            {!showNativeAppAd && <SuppressAndroidInstallPrompt />}

            {enableCrisisBanner && !isNativeChannel && <CrisisBanner />}

            {isKraken ? (
              <KrakenAppContent
                title={title}
                showHeader={showHeader}
                actionBar={actionBar}
                enableCrisisBanner={enableCrisisBanner}
              />
            ) : (
              <UnauthAppContent
                actionBar={actionBar}
                showHeader={showHeader}
                title={title}
                enableCrisisBanner={enableCrisisBanner}
              />
            )}
            {breadcrumbsContent && <Breadcrumbs data={breadcrumbsContent} />}
          </PrefetchContext.Provider>
        </TrackingProvider>
      </OriginThemeProvider>
    </>
  );
};

export { App };
