import * as React from "react";
import styled from "styled-components";

import { Section, TrackedLink } from "@origin-digital/ods-core";
import { Menu as MenuIcon } from "../Svg/MenuIcon";
import { Logo } from "../Svg/Logo";
import { LandscapeLogo } from "../Svg/LandscapeLogo";
import { SecondaryNav } from "./SecondaryNav";
import { PrimaryNav } from "./PrimaryNav";
import { TrackedIconButton } from "./MobileSlideinNav-styles";

const SidePadder = styled.div`
  /* Tuck in the sides of the children here so that the child component's
    bottom border doesn't extend to the sides */
  padding: 0 16px;
  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    padding: 0 24px;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    padding: 0;
  }
`;

const Container = styled.div`
  box-sizing: border-box;

  /* side padding is handled by the higher level SidePadder component. This way we 
  can have the bottom border be the full width of this container, since this
  container has already had its sides squeezed down by its parent. See 
  SidePadder above */
  border-bottom: 1px solid ${(p) => p.theme.colors.grey200};
  height: 72px;

  display: grid;
  grid-template-areas: "leftSection primaryNav . secondaryNav";
  grid-template-columns: auto auto 1fr auto;
  & > *:nth-child(1) {
    grid-area: leftSection;
  }
  & > *:nth-child(2) {
    grid-area: primaryNav;
  }
  & > *:nth-child(3) {
    grid-area: secondaryNav;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    border-bottom: none;
    height: 108px;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.xl}) {
    height: 132px;
  }
`;

const Left = styled.div`
  display: flex;
`;

export const Hamburger = styled(TrackedIconButton)`
  margin: auto 24px auto 0;
  svg {
    fill: ${(p) => p.theme.colors.grey500};
    width: 32px;
    height: 32px;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }
`;

export const MobileLogoLink = styled(TrackedLink)`
  margin: auto 0;
  & > svg {
    display: block;
    height: 32px;
    width: auto;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }
`;

export const DesktopLogoLink = styled(TrackedLink)`
  margin: auto 0;
  display: none;

  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: block;
    margin-right: 22px;
    & > svg {
      display: block;
      width: auto;
      height: 52px;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpoints.xl}) {
    margin-right: 36px;
    & > svg {
      height: 84px;
    }
  }
`;

export const MenuBar = ({ logoLinkHref, toggleMenu }) => {
  return (
    <SidePadder>
      <Section
        paddingY="none"
        hideGutter={[true, false]}
        fluidity={[true, false]}
      >
        <Container>
          <Left>
            <Hamburger
              trackingLabel="Hamburger Menu"
              data-id="mobile-navigation:hamburger-menu"
              data-comment="MobileNavButton"
              onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
                toggleMenu();
                e?.preventDefault();
              }}
            >
              <MenuIcon />
            </Hamburger>
            <MobileLogoLink
              trackingType="Link"
              trackingLabel="Mobile Logo"
              data-id="mobile-navigation:mobile-logo"
              href={logoLinkHref}
            >
              <LandscapeLogo id="mobile-nav" />
            </MobileLogoLink>
            <DesktopLogoLink
              trackingType="Link"
              trackingLabel="Desktop Logo"
              data-id="navigation:desktop-logo"
              href={logoLinkHref}
            >
              <Logo id="desktop-nav" />
            </DesktopLogoLink>
          </Left>
          <PrimaryNav />
          <SecondaryNav />
        </Container>
      </Section>
    </SidePadder>
  );
};
