import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const move_KRAKEN: IMcAppType = {
  name: "move_KRAKEN",
  path: "/moving/",
  parameters: {
    properties: {
      book: {
        type: "boolean",
        description: "Show 'Book my Move' button",
      },
    },
  },
  description: "Move house",
  navFlow: NavFlow.STANDARD,
  title: "Move",
  parent: Tab.MORE,
  conditions: {
    intent: "move",
  },
};
