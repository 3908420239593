import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

const { accountNumbers } = CommonParameters;

export const paymentSettingsMethod: IMcAppType = {
  name: "paymentSettingsMethod",
  path: "/my/payment-settings/method",
  parameters: {
    properties: { accountNumbers },
  },
  description: "Payment method for a set of given accounts numbers",
  navFlow: NavFlow.FOCUS,
  title: "Payment method",
  parent: Tab.MORE,
  conditions: {
    intent: "paymentSettingsMethod",
    customerType: CustomerType.kraken,
  },
};
