import React from "react";

import { Stack, Strong, Text } from "@origin-digital/ods-core";
import styled from "styled-components";
import nav from "./links-mobile.json";

import {
  MenuContainer,
  CloseButton,
  Link,
  LinkContainer,
  ShadeOverlay,
  TrackedIconButton,
} from "./MobileSlideinNav-styles";

type Props = {
  showMenu: boolean;
  onCloseClick: (e: React.MouseEvent<HTMLButtonElement> | undefined) => void;
};

const TrackedCloseButtonWrapper = styled(TrackedIconButton)`
  margin-bottom: 32px;
`;

const PrimaryLinkList = styled.div`
  /* flex gap still not supported by some of our supported browsers (I think it's IOS).
  We can use grid gap as a drop in replacement instead */
  display: grid;
  gap: 24px;

  @media screen and (min-width: 360px) {
    gap: 32px;
  }
`;

export const MobileSlideinNav = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);

  // encapsulate in a ref so the listener in useEffect can pick up the change
  // without requiring it in useEffect dependencies
  const showMenuRef = React.useRef(props.showMenu);
  React.useEffect(() => {
    showMenuRef.current = props.showMenu;
  }, [props.showMenu]);

  // For accessibility, when the menu is open (fullscreen), trap tab selections to the menu
  React.useEffect(() => {
    const listener = (e) => {
      if (!(e.key === "Tab")) return;
      if (!ref.current) return;
      if (!showMenuRef.current) return;

      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

      const focusableContent =
        ref.current.querySelectorAll<HTMLDivElement>(focusableElements);

      const firstFocusableElement = focusableContent[0];
      const lastFocusableElement =
        focusableContent[focusableContent.length - 1];

      const isFirstSelected = document.activeElement === firstFocusableElement;
      const isLastSelected = document.activeElement === lastFocusableElement;

      let isSlideinLink = false;
      focusableContent.forEach((el) => {
        if (document.activeElement === el) isSlideinLink = true;
      });

      if (e.shiftKey && (isFirstSelected || !isSlideinLink)) {
        lastFocusableElement.focus();
        e.preventDefault();
      } else if (!e.shiftKey && (isLastSelected || !isSlideinLink)) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, []);

  const { showMenu, onCloseClick } = props;
  return (
    <div ref={ref}>
      <ShadeOverlay className={showMenu ? "showMenu" : ""} />
      <MenuContainer
        padding="large"
        className={showMenu ? "showMenu" : ""}
        data-comment="MobileNavVisible"
        domProps={{ role: "dialog" }}
        aria-modal="true"
      >
        <TrackedCloseButtonWrapper
          onClick={(e) => {
            onCloseClick(e);
            e?.preventDefault();
          }}
          trackingLabel="Close Button"
          data-id="mobile-navigation:close-button"
        >
          <CloseButton />
        </TrackedCloseButtonWrapper>
        <Stack space="xlarge">
          <PrimaryLinkList>
            {nav.primaryLinks.map((link) => (
              <LinkContainer key={link.title}>
                <Link
                  href={link.link}
                  trackingType="Link"
                  trackingLabel={link.title}
                  data-id={`mobile-navigation:${link.title}`}
                  domProps={{ tabIndex: showMenu ? undefined : -1 }}
                >
                  <Strong>{link.title}</Strong>
                </Link>
              </LinkContainer>
            ))}
          </PrimaryLinkList>
          <Stack space="large">
            {nav.secondaryLinks.map((link) => (
              <LinkContainer key={link.title}>
                <Link
                  href={link.link}
                  trackingType="Link"
                  trackingLabel={link.title}
                  data-id={`mobile-navigation:${link.title}`}
                  domProps={{ tabIndex: showMenu ? undefined : -1 }}
                >
                  <Text>{link.title}</Text>
                </Link>
              </LinkContainer>
            ))}
          </Stack>
        </Stack>
      </MenuContainer>
    </div>
  );
};
