import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const dashboard_KRAKEN: IMcAppType = {
  name: "dashboard_KRAKEN",
  path: "/my/",
  description: "Users dashboard",
  navFlow: NavFlow.TAB,
  title: "My Account",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "dashboard",
    customerType: CustomerType.kraken,
  },
};
